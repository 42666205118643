function contactFormRecaptcha() {
  $('#contact-form [type="submit"]').prop("disabled", false);
}

function expiredContactFormCaptcha() {
  $('#contact-form [type="submit"]').prop("disabled", true);
}

function resetCaptcha() {
  grecaptcha.reset();
}